<template>
    <b-card class="mb-1" style="height: 294px">
        <b-card-title> 对标 </b-card-title>
        <div id="project-detail-benchmarking-1" style="height: 4.5rem"></div>
        <div
            id="project-detail-benchmarking-2"
            class="my-1"
            style="height: 4.5rem"
        ></div>
        <div id="project-detail-benchmarking-3" style="height: 4.5rem"></div>
    </b-card>
</template>

<script>
import { Bullet } from "@antv/g2plot";
import { onMounted } from "@vue/composition-api";
import { $themeColors } from "@themeConfig";

export default {
    name: "Benchmarking",
    setup() {
        const bulletChart1Init = () => {
            const bulletPlot = new Bullet("project-detail-benchmarking-1", {
                theme: {
                    colors10: Object.values($themeColors),
                },
                data: [
                    {
                        title: "COP",
                        ranges: [3.5, 4.15, 5, 10],
                        measures: [5.6],
                        target: 7,
                    },
                ],
                measureField: "measures",
                rangeField: "ranges",
                targetField: "target",
                xField: "title",
                color: {
                    range: ["#ea5455", "#ff9f43", "#00cfe8", "#28c76f"],
                    measure: "#1890FF",
                    target: "#28c76f",
                },
                size: {
                    range: 15,
                    measure: 10,
                    target: 30,
                },
                xAxis: {
                    line: null,
                },
                yAxis: {},
                label: {
                    target: {
                        offsetX: -16,
                        offsetY: -22,
                        style: {
                            fill: "#28c76f",
                        },
                    },
                    measure: {
                        style: {
                            fill: "#1890FF",
                        },
                    },
                },
                // 自定义 legend
                legend: {
                    custom: true,
                    position: "bottom",
                    itemName: {
                        style: {
                            fill: "#808695",
                        },
                    },
                    items: [
                        {
                            value: "差",
                            name: "差",
                            marker: {
                                style: { fill: "#ea5455" },
                            },
                        },
                        {
                            value: "中",
                            name: "中",
                            marker: {
                                style: { fill: "#ff9f43" },
                            },
                        },
                        {
                            value: "良",
                            name: "良",
                            marker: {
                                style: { fill: "#00cfe8" },
                            },
                        },
                        {
                            value: "优",
                            name: "优",
                            marker: {
                                style: { fill: "#28c76f" },
                            },
                        },
                        {
                            value: "实际值",
                            name: "实际值",
                            marker: {
                                symbol: "square",
                                style: { fill: "#1890FF" },
                            },
                        },
                        {
                            value: "高效机房目标值",
                            name: "高效机房目标值",
                            marker: {
                                symbol: "line",
                                style: { stroke: "#28c76f" },
                            },
                        },
                    ],
                },
            });
            bulletPlot.render();
        };
        const bulletChart2Init = () => {
            const bulletPlot = new Bullet("project-detail-benchmarking-2", {
                theme: {
                    colors10: Object.values($themeColors),
                },
                data: [
                    {
                        title: "建筑能耗综合值",
                        ranges: [100],
                        measures: [48],
                        target: 59,
                    },
                ],
                measureField: "measures",
                rangeField: "ranges",
                targetField: "target",
                xField: "title",
                color: {
                    range: ["l(0) 0:#28c76f 1:#ea5455"],
                    measure: "#1890FF",
                    target: "#28c76f",
                },
                size: {
                    range: 15,
                    measure: 10,
                    target: 30,
                },
                xAxis: {
                    line: null,
                },
                yAxis: {},
                label: {
                    target: {
                        offsetX: -18,
                        offsetY: -22,
                        style: {
                            fill: "#28c76f",
                        },
                    },
                    measure: {
                        style: {
                            fill: "#1890FF",
                        },
                    },
                },
                // 自定义 legend
                legend: {
                    custom: true,
                    position: "bottom",
                    itemName: {
                        style: {
                            fill: "#808695",
                        },
                    },
                    items: [
                        {
                            value: "优",
                            name: "优",
                            marker: {
                                style: { fill: "#28c76f" },
                            },
                        },
                        {
                            value: "差",
                            name: "差",
                            marker: {
                                style: { fill: "#ea5455" },
                            },
                        },
                        {
                            value: "实际值",
                            name: "实际值",
                            marker: {
                                symbol: "square",
                                style: { fill: "#1890FF" },
                            },
                        },
                        {
                            value: "近零能耗建筑目标值",
                            name: "近零能耗建筑目标值",
                            marker: {
                                symbol: "line",
                                style: { stroke: "#28c76f" },
                            },
                        },
                    ],
                },
            });
            bulletPlot.render();
        };
        const bulletChart3Init = () => {
            const bulletPlot = new Bullet("project-detail-benchmarking-3", {
                theme: {
                    colors10: Object.values($themeColors),
                },
                data: [
                    {
                        title: "PMV",
                        ranges: [-3, 0],
                        measures: [0.3],
                        target: 0,
                    },
                ],
                measureField: "measures",
                rangeField: "ranges",
                targetField: "target",
                xField: "title",
                color: {
                    range: [
                        "l(0) 0:#00cfe8 1:#28c76f",
                        "l(0) 0:#28c76f 1:#ea5455",
                    ],
                    measure: "#1890FF",
                    target: "#28c76f",
                },
                size: {
                    range: 15,
                    measure: 10,
                    target: 30,
                },
                xAxis: {
                    line: null,
                },
                yAxis: {},
                label: {
                    target: {
                        offsetX: -15,
                        offsetY: -22,
                        style: {
                            fill: "#28c76f",
                        },
                    },
                    measure: {
                        style: {
                            fill: "#1890FF",
                        },
                    },
                },
                // 自定义 legend
                legend: {
                    custom: true,
                    position: "bottom",
                    itemName: {
                        style: {
                            fill: "#808695",
                        },
                    },
                    items: [
                        {
                            value: "冷",
                            name: "冷",
                            marker: {
                                style: { fill: "#00cfe8" },
                            },
                        },
                        {
                            value: "舒适",
                            name: "舒适",
                            marker: {
                                style: { fill: "#28c76f" },
                            },
                        },
                        {
                            value: "热",
                            name: "热",
                            marker: {
                                style: { fill: "#ea5455" },
                            },
                        },
                        {
                            value: "实际值",
                            name: "实际值",
                            marker: {
                                symbol: "square",
                                style: { fill: "#1890FF" },
                            },
                        },
                        {
                            value: "舒适度目标值",
                            name: "舒适度目标值",
                            marker: {
                                symbol: "line",
                                style: { stroke: "#28c76f" },
                            },
                        },
                    ],
                },
            });

            bulletPlot.render();
        };

        onMounted(() => {
            bulletChart1Init();
            bulletChart2Init();
            bulletChart3Init();
        });
    },
};
</script>

<style scoped></style>
