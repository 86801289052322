<template>
    <div>
        <!-- top -->
        <!-- <b-card no-body class="text-center headerTitle py-50">
            <h1 class="font-title">{{ projectInfo.project_name }}</h1>
            <p class="mb-0">
                {{ projectInfo.project_en_name }}
            </p>
        </b-card> -->

        <section id="detail-info">
            <b-row>
                <b-col cols="2">
                    <!-- 基础信息 height 114px -->
                    <!-- <params-left :project_info="projectInfo"></params-left> -->
                    <!-- 能耗 height 858px -->
                    <energy :project_energy="projectEnergy"></energy>
                </b-col>

                <b-col cols="8">
                    <b-row>
                        <b-col cols="3">
                            <!-- 设备 height 658px -->
                            <equipment></equipment>
                        </b-col>

                        <!-- 综合信息 -->
                        <b-col cols="6">
                            <h1 class="text-center font-title">
                                {{ projectInfo.project_name }}
                            </h1>
                            <p class="text-center">
                                {{ projectInfo.project_en_name }}
                            </p>

                            <certificates></certificates>

                            <center :project_info="projectInfo"></center>

                            <center-bottom
                                :project_info="projectInfo"
                            ></center-bottom>
                        </b-col>

                        <b-col cols="3">
                            <!-- 运维 height 658px -->
                            <operation
                                :project_operation="projectOperation"
                            ></operation>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="6">
                            <!-- <benchmarking></benchmarking> -->
                            <prediction
                                :project_prediction="projectOptimization"
                            ></prediction>
                        </b-col>

                        <b-col cols="6">
                            <optimization
                                :project_optimization="projectOptimization"
                            ></optimization>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col cols="2">
                    <!-- 室外 height 180px -->
                    <!-- <params-right :project_info="projectInfo" :project_params="projectParams.current"></params-right> -->

                    <!-- 环境 height 792px -->
                    <environment
                        :project_params="projectParams"
                        :project_info="projectInfo"
                    ></environment>
                </b-col>
            </b-row>
        </section>
    </div>
</template>

<script>
import store from "@/store";
import { showToast } from "@/libs/utils/showToast";
import { computed, onMounted, reactive, toRefs } from "@vue/composition-api";
import { momentJs } from "@/libs/utils/moment";
import ParamsRight from "./components/ParamsRight.vue";
import ParamsLeft from "./components/ParamsLeft.vue";
import Benchmarking from "./components/Benchmarking.vue";
import Center from "./components/Center.vue";
import CenterBottom from "./components/CenterBottom";
import Energy from "./components/Energy.vue";
import Environment from "./components/Environment.vue";
import Equipment from "./components/Equipment.vue";
import Operation from "./components/Operation.vue";
import Prediction from "./components/Prediction.vue";
import Optimization from "./components/Optimization";
import Certificates from "./components/Certificates";
import { routerParams } from "@/libs/utils/routerParams";

export default {
    name: "ProjectDetail",
    components: {
        ParamsRight,
        ParamsLeft,
        Benchmarking,
        Center,
        Energy,
        Environment,
        Equipment,
        Operation,
        Prediction,
        Optimization,
        CenterBottom,
        Certificates,
    },
    setup() {
        const event = reactive({
            project_id: computed(() => {
                return routerParams("project_id");
            }),
            projectInfo: computed(() => {
                return store.state.projects.selected;
            }),
            projectEnergy: {},
            projectParams: {
                current: {},
                list: {},
            },
            projectOperation: {
                alarm: [],
                inspection: [],
                repair: [],
                agent: [],
            },
            projectOptimization: {
                energy_list: [],
                prediction_list: [],
            },
        });

        const getProjectInfo = () => {
            const project_id = event.project_id;
            store
                .dispatch("projects/fetchProject", project_id)
                .then((response) => {
                    event.projectInfo = response.data;
                })
                .catch((error) => {
                    showToast(error);
                });
        };

        const getProjectEnergy = () => {
            const project_id = event.project_id;
            store
                .dispatch("projects/fetchProjectEnergy", project_id)
                .then((response) => {
                    event.projectEnergy = response.data;
                })
                .catch((error) => {
                    showToast(error);
                });
        };

        const getProjectParams = () => {
            const project_id = event.project_id;
            store
                .dispatch("projects/fetchProjectParams", project_id)
                .then((response) => {
                    event.projectParams = response.data;
                })
                .catch((error) => {
                    showToast(error);
                });
        };

        const getProjectOperation = () => {
            const project_id = event.project_id;
            store
                .dispatch("projects/fetchProjectOperation", project_id)
                .then((response) => {
                    event.projectOperation = response.data;
                })
                .catch((error) => {
                    showToast(error);
                });
        };

        const getProjectOptimization = () => {
            const project_id = event.project_id;
            store
                .dispatch("projects/fetchProjectOptimization", project_id)
                .then((response) => {
                    console.log("OptimizationData", response.data);
                    event.projectOptimization = response.data;
                })
                .catch((error) => {
                    showToast(error);
                });
        };

        onMounted(() => {
            getProjectEnergy();
            getProjectParams();
            getProjectOperation();
            setTimeout(() => {
                // getProjectInfo();
                getProjectOptimization();
            }, 2000);
        });
        return {
            ...toRefs(event),
            momentJs,
        };
    },
};
/* eslint-disable global-require */
</script>

<style lang="scss" scoped>
.dark-layout .nav-tabs .nav-item .nav-link.active,
.dark-layout .nav-pills .nav-item .nav-link.active,
.dark-layout .nav-tabs.nav-justified .nav-item .nav-link.active {
    background: transparent !important;
}

.headerTitle {
    position: absolute;
    z-index: 999;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 40rem;
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
    box-shadow: none !important;
    border-bottom: 2px solid rgba(158, 158, 158, 0.5);

    .dark-layout & {
        border-bottom: 2px solid rgba(24, 144, 255, 0.5);
    }
}
</style>
