<template>
    <b-card>
        <b-card-sub-title>
            <span>{{ projectInfo.city || "N/A" }}</span>
            <span class="ml-2"
                >{{ projectInfo.lng ? projectInfo.lng.toFixed(2) : "N/A" }}° E,
                {{ projectInfo.lat ? projectInfo.lat.toFixed(2) : "N/A" }}°
                N</span
            >
        </b-card-sub-title>

        <b-row>
            <b-col cols="6">
                <h4 class="my-0">
                    {{
                        projectParams["OUTTEMP"]
                            ? projectParams["OUTTEMP"].toFixed(1)
                            : "N/A"
                    }}
                    <small class="text-muted">℃</small>
                </h4>
                <label>温度</label>
            </b-col>

            <b-col cols="6">
                <h4 class="my-0">
                    {{ projectParams["OUTDQY"] || "N/A" }}

                    <small class="text-muted"> hPa</small>
                </h4>
                <label>大气压</label>
            </b-col>

            <b-col cols="6">
                <h4 class="my-0">
                    {{
                        projectParams["OUTHUMI"]
                            ? (projectParams["OUTHUMI"] / 100).toFixed(1)
                            : "N/A"
                    }}

                    <small class="text-muted"> %</small>
                </h4>
                <label>湿度</label>
            </b-col>

            <b-col cols="6">
                <h4 class="my-0">
                    {{ projectParams["OUTFZD"] || "N/A" }}
                    <small class="text-muted"> W/m<sup>2</sup></small>
                </h4>
                <label>太阳辐照</label>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { computed, reactive, toRefs } from "@vue/composition-api";

export default {
    name: "ParamsRight",
    props: {
        project_params: [Object, Array],
        project_info: [Object],
    },
    setup(props) {
        const event = reactive({
            projectParams: computed(() => {
                return (
                    props.project_params || {
                        OUTTEMP: null,
                        "OUTPM2.5": null,
                        OUTHUMI: null,
                        OUTFZD: null,
                    }
                );
            }),
            projectInfo: computed(() => {
                return (
                    props.project_info || { city: null, lng: null, lat: null }
                );
            }),
        });
        return {
            ...toRefs(event),
        };
    },
};
</script>

<style scoped></style>
